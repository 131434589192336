import axios from '@/common/axios'
import qs from 'qs'

export function addSpecialReviewReport (data) {
  return axios({
    method: 'post',
    url: '/technology/reviewReport/add',
    data: qs.stringify(data)
  })
}

export function deleteSpecialReviewReport (id) {
  return axios({
    method: 'delete',
    url: '/technology/reviewReport/delete/' + id
  })
}

export function updateSpecialReviewReport (data) {
  return axios({
    method: 'put',
    url: '/technology/reviewReport/update',
    data: qs.stringify(data)
  })
}

export function selectSpecialReviewReportInfo (id) {
  return axios({
    method: 'get',
    url: '/technology/reviewReport/info/' + id
  })
}

export function selectSpecialReviewReportList (query) {
  return axios({
    method: 'get',
    url: '/technology/reviewReport/list',
    params: query
  })
}
