<template>
  <div id="specialReviewReport">
    <el-dialog
      :title="specialReviewReportFormTitle"
      width="680px"
      :visible.sync="specialReviewReportDialogVisible"
      :close-on-click-modal="false"
      @close="specialReviewReportDialogClose"
    >
      <el-form
        ref="specialReviewReportFormRef"
        :model="specialReviewReportForm"
        :rules="specialReviewReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="产品名称" prop="productName">
              <el-input v-model="specialReviewReportForm.productName" placeholder="请输入产品名称" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="工艺步骤" prop="processStep">
              <el-input
                v-model="specialReviewReportForm.processStep"
                placeholder="请输入工艺步骤"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="评审意见" prop="reviewOpinion">
              <el-input
                v-model="specialReviewReportForm.reviewOpinion"
                placeholder="评审意见"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准意见" prop="approval">
              <el-input
                v-model="specialReviewReportForm.approval"
                placeholder="请输入批准意见"
                type="textarea"
                :rows="4"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审人" prop="reviewer">
              <el-input v-model="specialReviewReportForm.reviewer" placeholder="请输入评审人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准人" prop="approver">
              <el-input v-model="specialReviewReportForm.approver" placeholder="请输入批准人" clearable />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="评审日期" prop="reviewTime">
              <el-date-picker v-model="specialReviewReportForm.reviewTime" placeholder="请选择评审日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准日期" prop="approveTime">
              <el-date-picker v-model="specialReviewReportForm.approveTime" placeholder="请选择批准日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="specialReviewReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="specialReviewReportFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="产品名称">
        <el-input v-model="searchForm.productName" placeholder="请输入产品名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button type="primary" icon="el-icon-plus" @click="handleAdd">
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="specialReviewReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :height="getTableHeight()"
    >
      <el-table-column prop="productName" label="产品名称" />
      <el-table-column prop="processStep" label="工艺步骤" />
      <el-table-column prop="reviewOpinion" label="评审意见" />
      <el-table-column prop="reviewer" label="评审人" />
      <el-table-column label="评审日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewTime">{{ scope.row.reviewTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="approval" label="批准意见" />
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="批准日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approveTime">{{ scope.row.approveTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="specialReviewReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import { addSpecialReviewReport, deleteSpecialReviewReport, updateSpecialReviewReport, selectSpecialReviewReportInfo, selectSpecialReviewReportList } from '@/api/technology/specialReviewReport'

export default {
  data () {
    return {
      specialReviewReportDialogVisible: false,
      specialReviewReportFormTitle: '',
      specialReviewReportForm: {
        id: '',
        productName: '',
        processStep: '',
        reviewOpinion: '',
        reviewer: '',
        reviewTime: '',
        approval: '',
        approver: '',
        approveTime: ''
      },
      specialReviewReportFormRules: {
        productName: [{ required: true, message: '产品名称不能为空', trigger: ['blur', 'change']}]
      },
      specialReviewReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        productName: ''
      }
    }
  },
  created () {
    selectSpecialReviewReportList(this.searchForm).then(res => {
      this.specialReviewReportPage = res
    })
  },
  methods: {
    specialReviewReportDialogClose () {
      this.$refs.specialReviewReportFormRef.resetFields()
    },
    specialReviewReportFormSubmit () {
      if (this.specialReviewReportFormTitle === '特殊过程评审报告详情') {
        this.specialReviewReportDialogVisible = false
        return
      }
      this.$refs.specialReviewReportFormRef.validate(async valid => {
        if (valid) {
          if (this.specialReviewReportFormTitle === '新增特殊过程评审报告') {
            await addSpecialReviewReport(this.specialReviewReportForm)
          } else if (this.specialReviewReportFormTitle === '修改特殊过程评审报告') {
            await updateSpecialReviewReport(this.specialReviewReportForm)
          }
          this.specialReviewReportPage = await selectSpecialReviewReportList(this.searchForm)
          this.specialReviewReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.specialReviewReportFormTitle = '新增特殊过程评审报告'
      this.specialReviewReportDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSpecialReviewReport(row.id)
        if (this.specialReviewReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.specialReviewReportPage = await selectSpecialReviewReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.specialReviewReportFormTitle = '修改特殊过程评审报告'
      this.specialReviewReportDialogVisible = true
      this.selectSpecialReviewReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.specialReviewReportFormTitle = '特殊过程评审报告详情'
      this.specialReviewReportDialogVisible = true
      this.selectSpecialReviewReportInfoById(row.id)
    },
    selectSpecialReviewReportInfoById (id) {
      selectSpecialReviewReportInfo(id).then(res => {
        this.specialReviewReportForm.id = res.id
        this.specialReviewReportForm.productName = res.productName
        this.specialReviewReportForm.processStep = res.processStep
        this.specialReviewReportForm.reviewOpinion = res.reviewOpinion
        this.specialReviewReportForm.reviewer = res.reviewer
        this.specialReviewReportForm.reviewTime = res.reviewTime
        this.specialReviewReportForm.approval = res.approval
        this.specialReviewReportForm.approver = res.approver
        this.specialReviewReportForm.approveTime = res.approveTime
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSpecialReviewReportList(this.searchForm).then(res => {
        this.specialReviewReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSpecialReviewReportList(this.searchForm).then(res => {
        this.specialReviewReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSpecialReviewReportList(this.searchForm).then(res => {
        this.specialReviewReportPage = res
      })
    }
  }
}
</script>

<style>
</style>
